import $ from 'jquery'
import 'bootstrap/js/src/dropdown'
import 'bootstrap/js/src/collapse'
import 'bootstrap/js/src/base-component'
import 'bootstrap/js/src/button'
import 'bootstrap/js/src/offcanvas'

// needed, so bootstrap detects and uses jquery
window.jQuery = $

$(document).ready(function () {
  // const $tooltipTriggerList = $('[data-bs-toggle="tooltip"]')
  // $tooltipTriggerList.each(function () {
  //     // eslint-disable-next-line no-unused-vars
  //     const tooltip = new Tooltip(this)
  // })
})
