export default class formField {
  constructor ($field) {
    this.$formRow = $field.closest('.formRow')
    this.$field = this.$formRow.find('input, select')
    this.$formError = this.$formRow.find('.formError')
    this.$errorIndicatorfield = this.$field.closest('.formErrorIndicator', this.$formRow.get(0))
    if (this.$errorIndicatorfield.length < 1) {
      this.$errorIndicatorfield = this.$field
    }

    this.$field.on('blur', this.handleFieldBlur.bind(this))
    this.$field.on('focus', this.handleFieldFocus.bind(this))
  }

  handleFieldBlur (event) {
    this.resetValidation()
  }

  handleFieldFocus (event) {
    this.resetValidation()
  }

  resetValidation () {
    this.$errorIndicatorfield.removeClass('is-invalid')
    this.$errorIndicatorfield.removeClass('is-valid')
    this.$formError.text('')
  }

  error (message) {
    this.resetValidation()
    this.$errorIndicatorfield.addClass('is-invalid')
    this.$formError.text(message)
  }

  valid () {
    this.resetValidation()
    this.$errorIndicatorfield.addClass('is-valid')
  }

  insertSuggestedFieldValue (value) {
    const tagName = this.$field.prop('tagName').toLowerCase()
    const type = this.$field.attr('type')

    switch (tagName) {
      case 'input':
        switch (type) {
          case 'text':
          case 'email':
          case 'password':
            this.$field.val(value)
            break
          case 'checkbox':
            this.$field.prop('checked', (value === this.$field.attr('value')))
            break
          default:
            console.error(`insertSuggestedFieldValue for "${tagName}"/"${type}" is not implemented`)
            break
        }
        break
      case 'select':
        console.error(`insertSuggestedFieldValue for "${tagName} is not implemented`)
        break
      case 'default':
        console.error(`insertSuggestedFieldValue for "${tagName} is not implemented`)
        break
    }
  }
}
