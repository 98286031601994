import $ from 'jquery'

export default class partnerAppointment {
  constructor (element) {
    this.$element = $(element)
    this.$form = this.$element.find('form')
    this.$checkbox = this.$form.find('input.form-check-input[name=privacyCheck]')
    this.$button = this.$form.find('.submitButton')
    this.$checkbox.on('change', this.handleSubmitState.bind(this))

    this.handleSubmitState()
  }

  handleSubmitState () {
    const checkboxState = this.$checkbox.prop('checked')
    this.$button.attr('disabled', !checkboxState)
  }
}
