/* global localStorage */
import $ from 'jquery'
import CookiesEuBanner from 'cookies-eu-banner'
import ExternalModules from './externalModules'

const Tracking = class Tracking {
  constructor () {
    this.allowTrackingCallbacks = []
    this.alreadyAllowed = false
  }

  initialize () {
    // remove old (maybe existing)
    if (localStorage) {
      localStorage.removeItem('hasConsent')
    }

    this.$consentDisplay = $('.cookieConsentDisplay')
    this.$buttons = $('.cookieConsentButtonBar button')

    this.$buttons.on('click', this.handleButtonClick.bind(this))

    this.$resetButtons = $('.consentResetButton')
    this.$resetButtons.on('click', this.handleResetButtonClick.bind(this))

    setTimeout(this.showConsentBar.bind(this), 1000)

    const that = this
    this.cookiesBanner = new CookiesEuBanner(function () {
      ExternalModules.addNonGDPR()

      for (const callback of that.allowTrackingCallbacks) {
        callback()
      }
      that.alreadyAllowed = true
    }, true, false)
  }

  handleButtonClick () {
    this.hideConsentBar()
  }

  hideConsentBar () {
    this.$consentDisplay.removeClass('show')
  }

  showConsentBar () {
    this.$consentDisplay.addClass('show')
  }

  resetConsent () {
    const hasContent = this.cookiesBanner.hasConsent()
    if (hasContent !== null) {
      this.cookiesBanner.deleteCookie(this.cookiesBanner.cookieName)
      window.setTimeout(() => {
        window.location.reload()
      }, 1000)
    }
  }

  onTrackingAllowed (callback) {
    if (this.alreadyAllowed) {
      callback()
    } else {
      this.allowTrackingCallbacks.push(callback)
    }
  };

  handleResetButtonClick (event) {
    event.preventDefault()
    this.reset()
  }

  reset () {
    if (this.cookiesBanner) {
      this.cookiesBanner.deleteCookie(this.cookiesBanner.cookieName)
      window.location.reload()
    }
  }
}

export default new Tracking()
